<script setup>
import { computed } from 'vue'
const props = defineProps({
  link: String,
  accountPlanId: [String, Number],
  showDescription: {
    type: Boolean,
    default: false
  },
  showLink: {
    type: Boolean,
    default: false
  },
  classes: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['engage', 'up', 'down'])
const links = [
  {
    name: 'Manage',
    icon: 'account',
    color: 'darkblue',
    link: props.accountPlanId ? `/manage/account/?id=${props.accountPlanId}` : '/manage/',
    description: 'Manage budgeting and settings on accounts, down to ad content.'
  },
  {
    name: 'Notifications',
    icon: 'bell',
    color: 'red',
    link: props.accountPlanId ? `/alerts/?view=account&accounts=${props.accountPlanId}` : '/alerts/',
    description: 'Troubleshoot issues and vet opportunities from an outlier-first approach.'
  },
  {
    name: 'Data Explorer',
    icon: 'analyze',
    color: 'lightgreen',
    link: props.accountPlanId ? `/reporting/data-explorer/?account=${props.accountPlanId}` : '/reporting/data-explorer/',
    description: 'Slice and dice reporting data to build custom data sets.'
  },
  {
    name: 'Dashboard',
    icon: 'dashboard',
    color: 'purple',
    link: props.accountPlanId ? `/dashboard/?accountPlanId=${props.accountPlanId}` : '/dashboard/'
  },
  {
    name: 'Peer Insights',
    icon: 'person',
    color: 'purple',
    link: props.accountPlanId ? `/insights/peer-insights/?account=${props.accountPlanId}` : '/insights/peer-insights/'
  },
  {
    name: 'Blueprints',
    icon: 'blueprints',
    color: 'purple',
    link: '/blueprints/',
    description: 'Layout the plan to launch advertising campaigns at massive scale.'
  }
]
const theLink = computed(() => links.find(l => l.name === props.link))
</script>
<template>
  <a class="d-flex-center jump-option"
     :class="props.classes"
     @keyup.down="emit('down')"
     @keyup.up="emit('up')"
     @keyup.enter="emit('engage', { link: theLink.link, nativeEvent: $event })"
     @click="emit('engage', { link: theLink.link, nativeEvent: $event })">
    <fluency-icon :type="theLink.icon" class="round mr-3 d-flex p-3" :class="'bg-' + theLink.color"></fluency-icon>
    <div>
      <div>{{theLink.name}}</div>
      <small v-if="props.showLink" class="text-muted">{{theLink.link}}</small>
      <small v-if="props.showDescription" class="text-muted">{{theLink.description}}</small>
    </div>
  </a>
</template>
<style lang="scss" scoped>
.bg-lightgreen {
  background: $fluency-lightgreen;
}
.bg-darkblue {
  background: $fluency-darkblue;
}
.bg-purple {
  background: $fluency-purple;
}
.bg-red {
  background: $fluency-red;
}
.jump-option {
  color: $secondary;
  cursor: pointer;
}
.jump-option:hover,
.jump-option:focus {
  color: $fluency-blue;
  outline: 0;
  .round {
    background: $fluency-blue !important;
  }
}
</style>
