<script setup>
import { computed, watch, onMounted } from 'vue'
import { useAccountsMinimal } from '../../composables/useAccountsMinimal'
import JumperQuickLink from './jumperQuickLink.vue'
const { $store, $router, $track, $res } = useNuxtApp()
const { accountsMinimal } = useAccountsMinimal()
const $route = useRoute()
const props = defineProps({
  userInput: String
})
const emit = defineEmits(['navigated'])
const navigation = computed(() => $store.getters.navigationForUser)
const flatNav = computed(() => {
  const nav = [...navigation.value.navigation]
  for (const d of [navigation.value.settings, navigation.value.reporting]) {
    for (const p of d) {
      for (const s of p.sections) {
        for (const l of s) {
          nav.push(l)
        }
      }
    }
  }
  return nav
})
const content = computed(() => {
  if (props.userInput.length > 2) {
    const accounts = accountsMinimal().filter(a => a.name.toLowerCase().includes(props.userInput.toLowerCase())) ?? []
    return {
      accounts: accounts.slice(0, 10),
      totAccount: accounts.length,
      nav: flatNav.value.filter(l => l.text.toLowerCase().includes(props.userInput.toLowerCase()))
    }
  }
  return {
    accounts: [],
    nav: []
  }
})
const hasContent = computed(() => content.value.accounts.length > 0 || content.value.nav.length > 0)
const quickLinks = ['Manage', 'Notifications', 'Data Explorer']
const adjustFocus = (modifier) => {
  const current = document.activeElement?.getAttribute('tabindex')
  if (current) {
    const newEl = document.querySelector(`[tabindex="${parseInt(current) + modifier}"]`)
    if (newEl) {
      newEl.focus()
    }
  }
}
const handleClick = ({ link, nativeEvent }) => {
  $track.event(
    'Jumper Navigate',
    {
      path: link
    },
    'Navigation',
    nativeEvent?.target
  )
  emit('navigated')
  $router.push(link)
}
const goToFirstLink = async () => {
  if (content.value.accounts?.length > 0) {
    handleClick({ link: `/manage/account/?id=${content.value.accounts[0].accountPlanId}` })
  } else if (content.value.nav?.length > 0) {
    handleClick({ link: content.value.nav[0].link })
  } else if ($store.getters.user?.internalUser && !isNaN(parseInt(props.userInput))) {
    const customer = await $res.fetch.getCustomerFromUrl(`/manage/account/?id=${props.userInput}`)
    if (customer) {
      const actingAs = $store.getters.actingAsUser
      if (actingAs && customer.customerId === actingAs) {
        handleClick({ link: `/manage/account/?id=${props.userInput}` })
      } else if (!actingAs && $store.user?.customerId === customer.customerId) {
        handleClick({ link: `/manage/account/?id=${props.userInput}` })
      } else {
        const env = $route.query.fsEnv
        window.location = `${window.location.origin}/manage/account/?id=${props.userInput}${env ? '&fsEnv=' + env : ''}`
      }
    }
  }
}
defineExpose({ goToFirstLink, adjustFocus })
onMounted(() => {
  if ($store.getters.user?.internalUser || $store.getters.user.capabilities?.PeerAnalysis) {
    quickLinks.push('Peer Insights')
  } else {
    quickLinks.push('Dashboard')
  }
})
</script>

<template>
  <div v-if="userInput.length > 2" id="jumper-content" class="jumper-content bg-white p-4">
    <template v-if="hasContent">
      <!--        Single Account-->

      <template v-if="content.accounts.length === 1">
        <div v-for="account in content.accounts" :key="account.accountPlanId" class="pb-3 px-4">
          <h5>{{account.name}}</h5>
          <div class="d-flex flex-wrap">
            <jumper-quick-link v-for="(ql, qli) in quickLinks"
                               :key="ql"
                               classes="mt-3 w-50"
                               :tabindex="601 + qli"
                               :account-plan-id="account.accountPlanId"
                               :link="ql"
                               show-link
                               @down="adjustFocus(1)"
                               @up="adjustFocus(-1)"
                               @engage="handleClick($event)">
            </jumper-quick-link>
          </div>
        </div>
      </template>

      <!--        Accounts-->

      <div v-if="content.accounts.length > 1" class="pb-3 px-4">
        <a v-for="(account, i) in content.accounts"
           :key="account.accountPlanId"
           class="d-flex-center mt-3 jump-option"
           :tabindex="601 + i"
           @keyup.down="adjustFocus(1)"
           @keyup.up="adjustFocus(-1)"
           @keyup.enter.stop="handleClick({ link:`/manage/account/?id=${account.accountPlanId}`, nativeEvent: $event })"
           @click="handleClick({ link: `/manage/account/?id=${account.accountPlanId}`, nativeEvent: $event })">
          <fluency-icon type="account" class="round mr-3 d-flex p-3 bg-darkblue"></fluency-icon>
          <div>
            <div>{{account.name}}</div>
            <small class="text-muted">/manage/account/?id={{account.accountPlanId}}</small>
          </div>
        </a>
        <div v-if="content.totAccount > 10" class="border-top pt-3 mt-3 px-4">
          {{content.totAccount - 10}} More. Refine Your Search To See More
        </div>
      </div>

      <!--        Nav Links-->

      <div v-if="content.nav.length > 0" class="pb-3 px-4" :class="{'border-top pt-4': content.totAccount > 0}">
        <a v-for="(link, i) in content.nav"
           :key="link.text"
           class="d-flex-center mt-3 jump-option"
           :tabindex="601 + i + (content.accounts.length === 1 ? 4 : content.accounts.length)"
           @keyup.down="adjustFocus(1)"
           @keyup.up="adjustFocus(-1)"
           @click="handleClick({ link: link.link, nativeEvent: $event })"
           @keyup.enter="handleClick({ link: link.link, nativeEvent: $event })">
          <fluency-icon type="link" class="round mr-3 d-flex p-3"></fluency-icon>
          <div>
            <div>{{link.text}}</div>
            <small class="text-muted">{{link.link}}</small>
          </div>
        </a>
      </div>
    </template>
    <template v-else>
      <span class="font-italic text-muted">Nothing to Show</span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.bg-lightgreen {
  background: $fluency-lightgreen;
}
.bg-darkblue {
  background: $fluency-darkblue;
}
.bg-purple {
  background: $fluency-purple;
}
.bg-red {
  background: $fluency-red;
}
.jump-option {
  color: $secondary;
  cursor: pointer;
}
.jump-option:hover,
.jump-option:focus {
  color: $fluency-blue;
  outline: 0;
  .round {
    background: $fluency-blue !important;
  }
}
</style>
